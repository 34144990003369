export enum NavigationUrl {
  Home = '/',
  Order = '/order',
  Update = '/update',
  SearchCertificates = '/search-certificates',
  Sign = '/sign',
  CheckSign = '/check-sign',
  ChangeStatus = '/change-status',
  Device = '/devices',
  Device0 = '/devices/0',
  Device1 = '/devices/1',
  Device2 = '/devices/2',
  Device3 = '/devices/3',
  Device4 = '/devices/4',
  Device5 = '/devices/5',
  Device6 = '/devices/6',
  Device7 = '/devices/7',
  Partners = '/partners',
  News = '/news',
  Contacts = '/contacts',
  About = '/about',
  Contract = '/contract',
  Statement = '/statement',
  DiiaProject = '/diia-project',
  DiiaEng = '/diia-eng',
  Software = '/software',
  Reglament = '/reglament',
  Keys = '/keys',
  Faq = '/faq',
  Partnerships = '/partnerships',
  Contracts = '/contracts',
  DigitalSignature = '/digital-signature',
  Login = '/login',
}

export enum NavigationLabel {
  TrustedServices = 'Довірчі послуги',
  PurchaseCertificate = 'Придбати сертифікат',
  UpdateCertificate = 'Оновити сертифікат',
  FindCertificate = 'Знайти сертифікат',
  SignDocument = 'Підписати документ',
  CheckSign = 'Перевірити підпис',
  ChangeStatus = 'Зміна статусу',
  ProtectedMedia = 'Захищені носії',
  Almaz1KPlastic = '«Алмаз-1К» (Пластмасовий корпус)',
  Almaz1KMetal = '«Алмаз-1К» (Металевий корпус)',
  Almaz1KBluetooth = '«Алмаз-1К» (Bluetooth-пристрій)',
  Crystal1 = '«Кристал-1»',
  Securetoken337K = '«Securetoken-337К»',
  Securetoken338M = '«Securetoken-338M»',
  Securetoken338S = '«Securetoken-338S»',
  KP382 = '«KP-382»',
  BecomeRepresentative = 'Стати представництвом',
  News = 'Новини',
  ServicePoints = 'Пункти обслуговування',
  DiiaID = 'Дія ID | Дія. Підпис',
  AboutDiiaSignature = 'Про Дія. Підпис',
  ContractQualifiedServices = 'Договір про надання кваліфікованих електронних довірчих послуг в межах проекту Дія. Підпис',
  StatementJoiningContract = 'Заява про приєднання до договору про надання кваліфікованих електронних довірчих послуг',
  DiiaProjectContract = 'Договір приєднання про підключення інформаційно-комунікаційної системи до Порталу Дія в межах проєкту Дія ID',
  DiiaEngContract = 'Договір приєднання про підключення інформаційно-комунікаційної системи до Порталу Дія в межах проєкту Дія ID (Двомовний)',
  More = 'Ще',
  Software = 'Програмне забезпечення',
  Regulations = 'Регламент та нормативні акти',
  SecureKeyMedia = 'Захищені носії ключів',
  QuestionsAndAnswers = 'Питання та відповіді',
  WhereSignatureWorks = 'Де працює підпис Дії',
  ContractForms = 'Форми договорів',
  HowElectronicSignatureWorks = 'Як працює електронний підпис?',
}
